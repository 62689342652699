export default function (device) {
    
  if(device.device_type == "camera"){
      if(device.id_type == 1){
        return `SBO/Camara PTZ/Camara PTZ.svg`;
      }

      return `SBO/Camara Minidomo/Camara minidomo.svg`;
  }

  if(device.camera_type == "interphone"){
      return `SBO/Interfon/Interfon.svg`;
  }

  if(device.camera_type == "density_device"){
      return `SBO/Generador de Neblina/Generador neblina.svg`;
  }

  if(device.camera_type == "button" || device.camera_type == "router"){
      return `SBO/Boton/Boton.svg`;
  }

  if(device.id_router){
    device.camera_type = "router"
    return `SBO/Boton/Boton.svg`;
  }

  if(device.device_type == 'sensor') {
      switch(device.id_type_sensor) {
        case 3:
          return `SBO/Vibracion/vibracion.svg`;
        case 10:
          return `SBO/Sensor de Humedad/Sensor humedad.svg`;
        case 11:
          return `SBO/Contacto Seco/Contacto seco.svg`;
        case 13:
          return `SBO/Voltaje/voltaje.svg`;
        default:
          return `SBO/Sensor de Temperatura/Sensor Temperatura.svg`;
      }
  }

  return `SBO/Salida de Alarma/Salida alarma.svg`;
}