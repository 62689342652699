<template>
  <div>
    <b-card class="m-2 p-2" no-body style="height: 78vh;">
      <b-row>
        <b-col cols="4" md="4">
          <div class="row-devices">
            <h1>{{ $t("plane.instructions") }}</h1>
            <div>
              <p><img width="5%" src="SBO/keyboard_icons/mouse-left-button.png"/> + <img width="5%" src="SBO/keyboard_icons/mouse-left-button.png"/> {{ $t("plane.add_device") }} </p>
              <p><img width="10%" src="SBO/keyboard_icons/shift.png"/> + <img width="5%" src="SBO/keyboard_icons/mouse-left-button.png"/> {{ $t("plane.move_plane") }}</p>
              <p><img width="10%" src="SBO/keyboard_icons/control.png"/> + <img width="5%" src="SBO/keyboard_icons/mouse-left-button.png"/> {{ $t("plane.rotate_device") }}</p>
            </div>
            <h1>{{ $t("plane.devices") }}</h1>
            <div v-if="cancel">
              <b-form-input
                v-model="namePlane"
                :placeholder="`${$t('plane.name')} ${$t('plane.title')}`"
                class="mt-3"
                ref="focused"
              ></b-form-input>
            </div>
            <img
              v-if="cancel"
              class="mt-2 mb-0"
              :src="textImage"
              style="height: 35px; margin-bottom: 20px;"
              alt=""
              @dblclick="addTextElement(this)"
            />
            <ul
              class="mt-2 pl-0"
              v-if="cancel"
            >
              <li
                class="text-cameras mb-1 cursor-pointer"
                v-for="camera in cameras"
                :key="camera.camera_proxy_id"
                :id="camera.camera_proxy_id"
                @dblclick="addArrayCameras(camera)"
              >

                <img
                  :src="setImage(camera)"
                  width="10%"
                />

                {{ camera.name }}
              </li>
              <li v-if="cameras.length == 0">
                <h2 class="text-cameras">No {{ $t("plane.devices") }}</h2>
              </li>
            </ul>
          </div>
          <div class="mt-1">
            <b-button
              variant="info"
              v-if="cancel"
              @click="envioDatos"
            >
              {{ $t("actions.save") }}
            </b-button>
          </div>
        </b-col>
        <b-col cols="8" md="8">
          <h1>{{ $t("plane.title") }}</h1>
          <b-tabs>
            <b-tab
              v-for="plane in planes"
              :key="plane.id"
              :title="`${plane.name}`"
            >
              <b-card-text>
                <div
                  class="contenedorPlane template"
                  :id="`plane-${plane.id}`"
                >
                  <b-img :src="`${imageUrl}${plane.path}`"/>
                  <device
                    v-for="device in plane.devices"
                    :key="device.name"
                    :position="device.position"
                    :name="device.name"
                    :device="device"
                  />
                  <span
                    v-for="text in plane.texts"
                    contenteditable="true"
                    :key="text"
                    :id="`text-${text}`"
                    class="text-droppable"
                    :data-width="text.position.width"
                    :data-height="text.position.height"
                    :data-x="text.position.x"
                    :data-y="text.position.y"
                    :data-angle="text.position.degree"
                    :style="`width: ${text.position.width}; height: ${text.position.height}; transform: translate(${text.position.x}px,${text.position.y}px) rotate(${text.position.degree}deg)`"
                  >
                    {{text.text}}
                  </span>
                  <b-button
                    variant="secondary"
                    class="px-1 remove-plane"
                    @click="deletePlane(plane.id)"
                  >
                    x
                  </b-button>
                </div>
              </b-card-text>
            </b-tab>

            <b-tab
              :title="`${$t('plane.add')} ${$t('plane.title')}`"
              active
              style="position: relative;"
            >
              <b-button
                v-if="cancel"
                variant="secondary"
                class="px-1 remove-plane"
                @click="removePlane()"
              >
                x
              </b-button>

              <vue-dropzone
                v-show="imagenMiniatura == null"
                id="dropzonePlane"
                ref="dropzonePlane"
                :options="dropzoneOptions"
                class="dropzone"
                :include-styling="false"
                @vdropzone-file-added="addFile"
                @vdropzone-removed-file="removeFile"
              />
              <div
                id="containerImage"
                class="hidden container-devices droppable-zone"
                ref="containerImage"
              >
                <camera
                  v-for="camera in arrayCameras"
                  :key="camera.camera_proxy_id"
                  :camera="camera"
                />

                <span
                  v-for="text in arrayText"
                  contenteditable="true"
                  :key="text"
                  :id="`text-${text}`"
                  class="text-droppable"
                  data-width="80"
                  data-height="35"
                  data-x="0"
                  data-y="0"
                  data-angle="0"
                  @click="focus"
                />
              </div>
            </b-tab>
          </b-tabs>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BTable,
  BButton,
  BDropdown,
  BDropdownItem,
  BPagination,
  BImg,
  BTabs,
  BTab,
  BCardText,
} from "bootstrap-vue"

import router from '@/router'
import { ref, onMounted, onUpdated } from "@vue/composition-api"
import vue2Dropzone from "vue2-dropzone"
import "vue2-dropzone/dist/vue2Dropzone.min.css"
import store from "@/store"
import interact from "interactjs"
import { useToast } from "vue-toastification/composition";
import { useUtils as useI18nUtils } from "@core/libs/i18n";
import Camera from "./Camera.vue"
import Device from "./Device.vue"
import Panzoom from "@panzoom/panzoom";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import setDeviceImage from './useList.js';

export default {
  components: {
    BCard,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BButton,
    BDropdown,
    BDropdownItem,
    BPagination,
    BImg,
    BTabs,
    BTab,
    BCardText,
    Camera,
    Device,
    vueDropzone: vue2Dropzone,
  },
  setup(_, context) {
    const id = router.currentRoute.params.id
    const cameras = ref("")
    const planes = ref("")
    const arrayCameras = ref([])
    const arrayText = ref([])
    const textId = ref(1)
    const cancel = ref(false)
    const imagenparaEnvio = ref(null)
    const imagenp = ref(null)
    const imagenMiniatura = ref(null)
    const dropzonePlane = ref(null)
    const template = ref(`
      <div class="template">
        <div class="dz-preview dz-file-preview" id="plano">
          <div id="imageContainer" class="droppable-zone" style="transform-origin: top left; width: fit-content; position: relative;">
            <img data-dz-thumbnail />
          </div>
        </div>
      </div>
    `)

    const textImage = require("@/assets/images/text-icon.png");

    const panzoom = ref(null)

    const containerImage = ref(null)
    const namePlane = ref('')

    const toast = useToast();
    const { t } = useI18nUtils();
    onMounted(() => {
      panzoom.value = Panzoom(containerImage.value,
      {
        maxScale: 5,
        canvas: true,
        noBind: true
      })
      panzoom.value.zoom(0.9, { animate: true })
      // containerImage.value.parentElement.addEventListener("wheel", panzoom.value.zoomWithWheel)
      containerImage.value.parentElement.addEventListener("wheel", function(e) {
        if (!e.shiftKey) return
          panzoom.value.zoomWithWheel(e)
      })

      containerImage.value.parentElement.addEventListener('pointerdown', (e) => {
        if (!e.shiftKey) return
          panzoom.value.handleDown(e)
      })
      document.addEventListener('pointermove', panzoom.value.handleMove)
      document.addEventListener('pointerup', panzoom.value.handleUp)
    })

    onUpdated(() => {
      let planes = document.querySelectorAll(`[id^='plane-']`)

      planes.forEach(plane => {
        let panzoom = Panzoom(plane,
        {
          maxScale: 5,
          canvas: true,
          noBind: true
        })
        panzoom.zoom(0.9, { animate: true })

        plane.parentElement.addEventListener("wheel", function(e) {
          if (!e.shiftKey) return
            panzoom.zoomWithWheel(e)
        })

        plane.parentElement.addEventListener('pointerdown', (e) => {
          if (!e.shiftKey) return
            panzoom.handleDown(e)
        })
        document.addEventListener('pointermove', panzoom.handleMove)
        document.addEventListener('pointerup', panzoom.handleUp)
      })
    })

    const addFile = () => {
      document.querySelector("#dropzonePlane").classList.add("hidden")
      document.querySelector("#containerImage").classList.remove("hidden")
      cancel.value = true
    }

    const removeFile = () => {
      document.querySelector("#dropzonePlane").classList.remove("hidden")
      document.querySelector("#containerImage").classList.add("hidden")
      cancel.value = false
    }

    const dropzoneOptions = ref({
      url: 'test',
      paramName: "plane",
      acceptedFiles: 'image/*',
      autoProcessQueue : false,
      uploadMultiple: false,
      thumbnailMethod: 'contain',
      previewsContainer: "#containerImage",
      thumbnailWidth: null,
      thumbnailHeight: null,
      previewTemplate: template,
      headers: {
          Authorization: `Bearer ${localStorage.accessToken}`
      },
      dictDefaultMessage: t('plane.image')
    })

    const refetchData = async () => {

        store.dispatch("unit/get", id)
          .then((res) => {

            cameras.value = res.data.cameras;
            planes.value = res.data.planes;
            let unitDescription = res.data.description;
            
            planes.value.forEach( (plane, i) => {

              plane.devices.forEach( (divice, j) => {

                cameras.value.forEach( (camera) => {

                  if(camera.camera_proxy_id == divice.id_camera_proxy){
                    planes.value[i].devices[j].device_type = camera.device_type
                    planes.value[i].devices[j].camera_type = camera.camera_type
                    planes.value[i].devices[j].id_type = (camera.id_type != undefined) ? camera.id_type : null
                    planes.value[i].devices[j].id_type_sensor = (camera.id_type_sensor != undefined) ? camera.id_type_sensor : null
                  }

                })
              })
            })

            store.dispatch("router/get", res.data.id_router)
              .then((res) => {

                const router = {
                  "id" : res.data.id,
                  "id_router" : res.data.id,
                  "id_camera_proxy" : res.data.id,
                  "camera_type": "router",
                  "name" : unitDescription,
                }

                cameras.value.push(router)
              })
          })
    }

    refetchData()

    const addArrayCameras = (camera) => {
      if (arrayCameras.value.includes(camera) == false) {
        arrayCameras.value.push(camera);
      }
    }

    const envioDatos = () => {
      let data = new FormData();
      data.append("name", namePlane.value);
      data.append("unitId", id);
      data.append("plane", dropzonePlane.value.getAcceptedFiles()[0])

      let devicesDOM = document.querySelectorAll(".container-devices .device")
      let textDOM = document.querySelectorAll(".container-devices .text-droppable")

      textDOM.forEach((text, i) => {
          data.append(`texts[${i}][degree]`, Math.round(text.dataset.degree))
          data.append(`texts[${i}][height]`, Math.round(text.dataset.height))
          data.append(`texts[${i}][width]`, Math.round(text.dataset.width))
          data.append(`texts[${i}][x]`, Math.round(text.dataset.x))
          data.append(`texts[${i}][y]`, Math.round(text.dataset.y))
          data.append(`texts[${i}][text]`, text.innerText)
      })

      devicesDOM.forEach((device, i) => {
          data.append(`devices[${i}][degree]`, Math.round(device.dataset.degree))
          data.append(`devices[${i}][height]`, Math.round(device.dataset.height))
          data.append(`devices[${i}][width]`, Math.round(device.dataset.width))
          data.append(`devices[${i}][x]`, Math.round(device.dataset.x))
          data.append(`devices[${i}][y]`, Math.round(device.dataset.y))
          data.append(`devices[${i}][name]`, device.title)
          data.append(`devices[${i}][id]`, Math.round(device.dataset.id))
          data.append(`devices[${i}][type]`, Math.round(device.dataset.type))
          data.append(`devices[${i}][id_camere_proxy]`, Math.round(device.dataset.id))
          data.append(`devices[${i}][id_router]`, '')

          if(device.dataset.idRouter){
            data.append(`devices[${i}][id_router]`, Math.round(device.dataset.id))
            data.append(`devices[${i}][id_camere_proxy]`, '')
          }
      })

      store
        .dispatch("plane/add", data)
        .then((res) => {
          refetchData()
          removePlane()
        })
        .catch((err) => {
          let message = err.response.data;
          toast({
            component: ToastificationContent,
            props: {
              title: message.data.name[0],
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
          focused.value.focus()
        });
        
    }

    interact('.droppable-zone').dropzone({
      overlap: 1,
      accept: '.device .text-droppable'
    })

    const getDragAngle = (event) => {
      var element = event.target
      var startAngle = parseFloat(element.dataset.angle) || 0
      var center = {
        x: parseFloat(element.dataset.centerX) || 0,
        y: parseFloat(element.dataset.centerY) || 0,
      }
      var angle = Math.atan2(center.y - event.clientY,
                            center.x - event.clientX)

      return angle - startAngle
    }

    let focused = ref(null)
    let rotateDevice = false
    let position = []

    const draggable = interact('.device')
      .resizable({
        // resize from all edges and corners
        edges: {
          left: false,
          right: true,
          bottom: false,
          top: false
        },

        listeners: {
          move(event) {
            if (event.ctrlKey) {
                return
            }
            var target = event.target

            var x = (parseFloat(target.getAttribute('data-x')) || 0)
            var y = (parseFloat(target.getAttribute('data-y')) || 0)

            // update the element's style
            target.style.width = event.rect.width + 'px'
            target.style.height = (event.rect.width * .58) + 'px'

            // translate when resizing from top or left edges
            x += event.deltaRect.left
            y += event.deltaRect.top

            let transform = target.style.transform.replaceAll(', ', ',').split(' ')

            transform = transform.filter(tranform => {
                return tranform.indexOf("translate") === -1
            })

            target.style.transform = 'translate(' + x + 'px,' + y + 'px) ' + transform.join(" ")

            target.setAttribute('data-x', x)
            target.setAttribute('data-y', y)
            target.setAttribute('data-width', event.rect.width)
            target.setAttribute('data-height', event.rect.width * .58)
          },
          end(event) {
              var target = event.target

              // $(target).tooltip('show')
          }
        },
        modifiers: [
          // minimum size
          interact.modifiers.restrictSize({
              min: {
                  width: 18,
                  height: 18
              },
              max: {
                  width: 128,
                  height: 128
              }
          })
        ],
        inertia: true
      })
      .draggable({
        listeners: {
          start(event) {
            const element = event.target
            const rect = element.getBoundingClientRect()

            // store the center as the element has css `transform-origin: center center`
            element.dataset.centerX = rect.left + rect.width / 2
            element.dataset.centerY = rect.top + rect.height / 2
            // get the angle of the element when the drag starts
            element.dataset.degree = Math.floor(getDragAngle(event) * (180 / Math.PI))
            element.dataset.angle = getDragAngle(event)
          },
          move(event) {
            let target = event.target

            if (event.ctrlKey) {
              let center = {
                x: parseFloat(target.dataset.centerX) || 0,
                y: parseFloat(target.dataset.centerY) || 0,
              }
              let angle = getDragAngle(event)
              let degree = Math.floor(getDragAngle(event) * (180 / Math.PI))

              // keep the dragged position in the data-x/data-y attributes
              var x = parseFloat(target.getAttribute('data-x')) || 0
              var y = parseFloat(target.getAttribute('data-y')) || 0

              target.style.transform = 'rotate(' + degree + 'deg' + ')'

              let transform = target.style.transform.replaceAll(', ', ',').split(' ')

              transform = transform.filter(tranform => {
                  return tranform.indexOf("translate") === -1
              })

              target.style.transform = 'translate(' + x + 'px,' + y + 'px) ' + transform.join(" ")

              // update transform style on dragmove
              rotateDevice = true 
              return
            }

            // keep the dragged position in the data-x/data-y attributes
            var x = (parseFloat(target.getAttribute('data-x')) || 0) + event.dx
            var y = (parseFloat(target.getAttribute('data-y')) || 0) + event.dy

            let transform = target.style.transform.replaceAll(', ', ',').split(' ')

            transform = transform.filter(tranform => {
                return tranform.indexOf("translate") === -1
            })

            target.style.transform = 'translate(' + x + 'px,' + y + 'px) ' + transform.join(" ")

            // update the posiion attributes
            target.setAttribute('data-x', x)
            target.setAttribute('data-y', y)
          },
          end(event) {
            var target = event.target

            target.dataset.degree = Math.floor(getDragAngle(event) * (180 / Math.PI))
            target.dataset.angle = getDragAngle(event)

            if(!rotateDevice){

              if(position.length > 0){
                position.findIndex((device) => {
                  if (device.id === target.dataset.id) {
                    target.dataset.degree = device.degree
                  }
                  else{
                    target.dataset.degree = 0
                  }
                });
              }
              else{
                target.dataset.degree = 0
              }
              
            }
            else{

              let devicePosition = {
                'id': target.dataset.id,
                'degree': target.dataset.degree
              }

              if(position.length == 0){
                position.push(devicePosition)
              }
              else{
                position.findIndex((device, indice) => {
                  if (device.id === target.dataset.id) {
                    position[indice] = devicePosition
                  }
                  else{
                    position.push(devicePosition)
                  }
                });
              }

            }

            rotateDevice = false
          }
        },
        inertia: false,
      })

    const draggableText = interact('.text-droppable')
      .resizable({
        // resize from all edges and corners
        edges: {
          left: false,
          right: true,
          bottom: false,
          top: false
        },
        listeners: {
          move(event) {
            if (event.ctrlKey) {
                return
            }
            var target = event.target

            // $(target).tooltip('hide')

            var x = parseFloat(target.getAttribute('data-x')) || 0
            var y = parseFloat(target.getAttribute('data-y')) || 0

            // update the element's style
            target.style.width = event.rect.width + 'px'

            // translate when resizing from top or left edges
            x += event.deltaRect.left
            y += event.deltaRect.top

            let transform = target.style.transform.replaceAll(', ', ',').split(' ')

            transform = transform.filter(tranform => {
                return tranform.indexOf("translate") === -1
            })

            target.style.transform = 'translate(' + x + 'px,' + y + 'px) ' + transform.join(" ")

            target.setAttribute('data-x', x)
            target.setAttribute('data-y', y)
            target.setAttribute('data-width', event.rect.width)
            target.setAttribute('data-height', event.rect.height)
          },
          end(event) {
            var target = event.target

            // $(target).tooltip('show')
          }
        },
        modifiers: [
          // keep the edges inside the parent
          interact.modifiers.restrictEdges({
            outer: 'parent'
          }),

          // minimum size
          interact.modifiers.restrictSize({
            min: {
              width: 50,
              height: 35
            },
            max: {
              width: 500,
              height: 250
            }
          })
        ],
        inertia: true
      })
      .draggable({
        listeners: {
          start(event) {
            const element = event.target
            const rect = element.getBoundingClientRect()

            // store the center as the element has css `transform-origin: center center`
            element.dataset.centerX = rect.left + rect.width / 2
            element.dataset.centerY = rect.top + rect.height / 2
            // get the angle of the element when the drag starts
            element.dataset.degree = Math.floor(getDragAngle(event) * (180 / Math.PI))
            element.dataset.angle = getDragAngle(event)
          },
          move(event) {
            let target = event.target

            if (event.ctrlKey) {
              let center = {
                x: parseFloat(target.dataset.centerX) || 0,
                y: parseFloat(target.dataset.centerY) || 0,
              }
              let angle = getDragAngle(event)
              let degree = Math.floor(getDragAngle(event) * (180 / Math.PI))

              // keep the dragged position in the data-x/data-y attributes
              var x = parseFloat(target.getAttribute('data-x')) || 0
              var y = parseFloat(target.getAttribute('data-y')) || 0

              target.style.transform = 'rotate(' + degree + 'deg' + ')'

              let transform = target.style.transform.replaceAll(', ', ',').split(' ')

              transform = transform.filter(tranform => {
                  return tranform.indexOf("translate") === -1
              })

              target.style.transform = 'translate(' + x + 'px,' + y + 'px) ' + transform.join(" ")
              return
            }

            // keep the dragged position in the data-x/data-y attributes
            var x = (parseFloat(target.getAttribute('data-x')) || 0) + event.dx
            var y = (parseFloat(target.getAttribute('data-y')) || 0) + event.dy

            let transform = target.style.transform.replaceAll(', ', ',').split(' ')

            transform = transform.filter(tranform => {
                return tranform.indexOf("translate") === -1
            })

            target.style.transform = 'translate(' + x + 'px,' + y + 'px) ' + transform.join(" ")

            // update the posiion attributes
            target.setAttribute('data-x', x)
            target.setAttribute('data-y', y)
            target.setAttribute('data-width', event.rect.width)
            target.setAttribute('data-height', event.rect.height)
          },
          end(event) {
            var target = event.target

            target.dataset.degree = Math.floor(getDragAngle(event) * (180 / Math.PI))
            target.dataset.angle = getDragAngle(event)
          }
        },
        inertia: false,
        modifiers: [
          interact.modifiers.restrictRect({
            restriction: 'parent',
            endOnly: true
          })
        ],
      })

    const addTextElement = () => {
      arrayText.value.push(textId.value)
      textId.value++
    }

    const focus = (event) => {
      event.target.focus()
    }

    const deletePlane = function(id) {
      const self = this;
      const storeInner = store;
      this.$swal({
        title: context.parent.$i18n.t("messages.delete.title"),
        text: context.parent.$i18n.t("messages.delete.body"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: context.parent.$i18n.t("actions.delete"),
        cancelButtonText: context.parent.$i18n.t("actions.cancel"),
        customClass: {
          confirmButton: "btn btn-primary text-capitalize",
          cancelButton: "btn btn-outline-danger ml-1 text-capitalize",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          storeInner
            .dispatch("plane/delete", id)
            .then((response) => {
              if (response.success) {
                refetchData()
                toast({
                  component: ToastificationContent,
                  props: {
                    title: response.message,
                    icon: "CheckIcon",
                    variant: "success",
                  },
                });
                self.refetchData();
              } else {
                toast(
                  {
                    component: ToastificationContent,
                    props: {
                      title: response.message,
                      icon: "AlertTriangleIcon",
                      variant: "danger",
                    },
                  },
                  {
                    timeout: 10000,
                  }
                );
              }
            })
            .catch((response) => {
              toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: response.response.data.message,
                    icon: "AlertTriangleIcon",
                    variant: "danger",
                  },
                },
                {
                  timeout: 10000,
                }
              )
            })
        }
      })
    }

    const removePlane = () => {
      cancel.value = !cancel.value
      arrayCameras.value = []
      dropzonePlane.value.removeAllFiles()
    }

    const setImage = (camera) => {
      return setDeviceImage(camera)
    }

    return {
      id,
      cameras,
      arrayCameras,
      arrayText,
      textId,
      planes,
      imagenp,
      imagenMiniatura,
      imagenparaEnvio,
      widhtl: 250,
      rotate: 0,
      cancel,
      widhtPlane: 100,
      heightl: 850,
      imageUrl: `${process.env.VUE_APP_BASE_URL}/v1/plane/image?image=`,
      idUnit: "",
      namePlane,

      dropzonePlane,

      addArrayCameras,
      addTextElement,
      envioDatos,

      dropzoneOptions,
      addFile,
      removeFile,
      deletePlane,
      removePlane,

      containerImage,

      textImage,
      focus,
      setImage,
      focused,
    }
  },
}
</script>

<style>
.template {
  min-width: 50vh;
  height: 60vh;
}

.text-cameras {
  font-size: 18px;
  font-weight: bold;
  -webkit-user-select: none;
}

.vdr.active:before {
  outline: none !important;
}

.contenedorPlane {
  background-size: 100%;
  background-repeat: no-repeat;
  position: relative;
}

ul {
  list-style: none;
}

h3 {
  font-weight: lighter;
}

.camera {
  display: inline-block;
  padding: 10px;
}

.rotate {
  transform: rotate(180deg);
}

.dropzone {
  height: 50vh;
  background: white;
  border-radius: 5px;
  border: 4px dashed #1b3d5e;
  border-image: none;
  margin-left: auto;
  margin-right: auto;
}

.dz-message {
    font-size: xx-large;
    color: #971f1e;
}

.dropzone .dz-preview .dz-details {
    opacity: 1 !important;
    padding: 1em 1em;
    position: relative;
}

.delete {
    position: absolute;
    right: 15px;
    top: 0;
}

.container-all {
    margin-bottom: 2rem;
}

.icon-devices {
    height: 25px;
    width: 45px;
}

.device {
    touch-action: none;
    user-select: none;
    height: 50px;
    width: 50px;
    position: absolute;
    top: 0;
    left: 15px;
}

.device-static {
    position: absolute;
    top: 0;
    left: 15px;
}

.text-droppable {
    touch-action: none;
    user-select: none;
    height: auto;
    width: 80px;
    border: 3px solid #1b3d5e;
    position: absolute;
    top: 0;
    left: 15px;
    background: black;
    opacity: .8;
    color: white;
    font-size: large;
    font-weight: 600;
}

.text-static {
    border: 3px solid #1b3d5e;
    position: absolute;
    top: 0;
    left: 15px;
    background: black;
    opacity: .8;
    color: white;
    font-size: large;
    font-weight: 600;
}

.remove-plane {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
}

.contenedorPlane {
    transform-origin: top left;
    width: fit-content;
    position: relative;
}

.row-devices{
  height: 65vh;
  overflow-y: scroll;
}
</style>
