<template>
  <div class="device">
    <VueDragResize
      :sticks="['tr']"
      :isActive="false"
      :w="position.width"
      :h="position.height"
      @resizing="resize"
      @dragging="resize"
      :x="position.x"
      :y="position.y"
      :isResizable="false"
      :isDraggable="false"
    >

      <img
        :src="setImage(device)"
        width="100%"
        :style="`transform: rotate(${position.degree}deg);`"
        v-b-tooltip.hover
        :title="name"
        @wheel.alt="onWheelC"
      />

    </VueDragResize>
  </div>
</template>

<script>
import VueDragResize from "vue-drag-resize";
import { VBTooltip } from "bootstrap-vue";
import setDeviceImage from './useList.js';

export default {
  name: "Device",
  components: {
    VueDragResize,
  },
  data() {
    return {
      width: 0,
      height: 0,
      top: 0,
      left: 0,
      rotate: 60,
    };
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  props: {
    position: Object,
    name: String,
    device: Object,
    required: true,
  },
  methods: {
    onWheelC(event) {
      if (event.deltaY >= 1) {
        this.rotate -= 10;
      } else {
        this.rotate += 10;
      }
    },
    resize(newRect) {
      this.width = newRect.width;
      this.height = newRect.height;
      this.top = newRect.top;
      this.left = newRect.left;
    },
    setImage(device){
      return setDeviceImage(device)
    },
  },
};
</script>

<style scoped>
.text {
  color: black;
}
.block {
  height: 100%;
  width: 100%;
  background-color: aqua;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
</style>
<style>
body,
html {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  overflow: auto;
}

.container {
  width: 300px;
  height: 300px;
  display: inline-block;
  border: 1px solid #dddddd;
  background: #ffffff;
  color: #333333;
  margin: 10px;
}

.table-block {
  display: table;
}

.table-row {
  display: table-row;
  text-align: center;
}

.table-cell {
  width: 50%;
  display: inline-block;
}

.table-input {
  width: 50px;
}

.drag-container-1,
.drag-container-2 {
  width: 100%;
  height: 20px;
  background: red;
  color: white;
  text-align: center;
  cursor: pointer;
}

.table-container {
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
</style>
