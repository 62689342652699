<template>
  <div
    class="device"
    v-b-tooltip.hover
    :title="camera.name"
    :data-id="camera.id_camera_proxy"
    :data-id-router="camera.id_router"
    data-type="1"
    data-x="0"
    data-y="0"
    data-degree="0"
    data-height="50"
    data-width="50"
    >

    <img
      :src="setImage(camera)"
      width="100%"
    />

  </div>
</template>

<script>
import setDeviceImage from './useList.js';

import {
  VBTooltip,
} from "bootstrap-vue"

export default {
  name: "Camera",
  props: {
    camera: {
      type: Object,
      required: true,
    },
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      width: 0,
      height: 0,
      top: 0,
      left: 0,
      rotate: 0,
    };
  },
  setup(props) {
    const rotateOnMouse = (e, pw) => {
        let offset = $(pw).offset()
        let center_x = (offset.left) + ($(pw).width() / 2)
        let center_y = (offset.top) + ($(pw).height() / 2)
        let mouse_x = e.pageX
        let mouse_y = e.pageY
        let radians = Math.atan2(mouse_x - center_x, mouse_y - center_y)
        let degree = Math.floor((radians * (180 / Math.PI) * -1) + 100)

        let transform = pw.style.transform.replaceAll(', ', ',').split(' ')

        transform = transform.filter(tranform => {
            return tranform.indexOf("rotate") === -1
        })

        pw.style.transform = transform.join(" ") + ' rotate(' + degree + 'deg)'

        pw.dataset.degree = degree
    }

    const rotate = (e) => {
        if (e.ctrlKey) {
            $(document).bind('mousemove.rotateImg', function(e2) {
                rotateOnMouse(e2, e.target.closest(".device"));
            });
        }
    }

    return {
      rotate
    }
  },
  methods: {
    setImage(device){
      return setDeviceImage(device)
    },
  }
};
</script>

<style scoped>
.block {
  height: 100%;
  width: 100%;
  background-color: aqua;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
</style>
<style>
body,
html {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  overflow: auto;
}

.container {
  width: 300px;
  height: 300px;
  display: inline-block;
  border: 1px solid #dddddd;
  background: #ffffff;
  color: #333333;
  margin: 10px;
}

.table-block {
  display: table;
}

.table-row {
  display: table-row;
  text-align: center;
}

.table-cell {
  width: 50%;
  display: inline-block;
}

.table-input {
  width: 50px;
}

.drag-container-1,
.drag-container-2 {
  width: 100%;
  height: 20px;
  background: red;
  color: white;
  text-align: center;
  cursor: pointer;
}

.table-container {
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
</style>
